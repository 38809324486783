// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import React from "react";

const Projects = ({ title, captions, data }) => {
  console.log("🚀 ~ data:", data);
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card
      my="0.1rem"
      overflowX={{ sm: "scroll", xl: "hidden" }}
      border="1px solid gray.700"
    >
      {/* <CardHeader p="6px 0px 22px 0px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            {title}
          </Text>
        </Flex>
      </CardHeader> */}
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".1rem" pl="0px">
              {captions.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => {
              console.log("🚀 ~ {data.map ~ row:", row);

              return (
                <TablesProjectRow
                  key={index}
                  category={row.productName}
                  amount={row.sum}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Projects;
