// Chakra imports
import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Custom icons
import {
  CartIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import React from "react";
import ChartStatistics from "./ChartStatistics";

const ActiveUsers = ({ title, title2, percentage, chart, top4Departments }) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card p="16px">
      <CardBody>
        <Flex direction="column" w="100%">
          <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
            {title}
          </Text>
          {chart}
          <Flex direction="column" mt="24px" mb="36px" alignSelf="flex-start">
            <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
              {title2}
            </Text>
            {/* <Text fontSize='md' fontWeight='medium' color='gray.400'>
              <Text
                as='span'
                color={percentage > 0 ? "green.400" : "red.400"}
                fontWeight='bold'>
                {percentage > 0 ? `+${percentage}%` : `-${percentage}%`}
              </Text>{" "}
              than last week
            </Text> */}
          </Flex>
          {top4Departments && (
            <SimpleGrid gap={{ sm: "12px" }} columns={{ base: "2", md: "4" }}>
              <ChartStatistics
                title={top4Departments[0]?.productSlug}
                amount={top4Departments[0]?.totalSales}
                percentage={
                  (top4Departments[0]?.totalSales * 100) /
                  (top4Departments[0]?.totalSales +
                    top4Departments[1]?.totalSales +
                    top4Departments[2]?.totalSales +
                    top4Departments[3]?.totalSales)
                }
                icon={
                  <WalletIcon h={"15px"} w={"15px"} color={iconBoxInside} />
                }
              />
              <ChartStatistics
                title={top4Departments[1]?.productSlug}
                amount={top4Departments[1]?.totalSales}
                percentage={
                  (top4Departments[1]?.totalSales * 100) /
                  (top4Departments[0]?.totalSales +
                    top4Departments[1]?.totalSales +
                    top4Departments[2]?.totalSales +
                    top4Departments[3]?.totalSales)
                }
                icon={
                  <RocketIcon h={"15px"} w={"15px"} color={iconBoxInside} />
                }
              />
              <ChartStatistics
                title={top4Departments[2]?.productSlug}
                amount={top4Departments[2]?.totalSales}
                percentage={
                  (top4Departments[2]?.totalSales * 100) /
                  (top4Departments[0]?.totalSales +
                    top4Departments[1]?.totalSales +
                    top4Departments[2]?.totalSales +
                    top4Departments[3]?.totalSales)
                }
                icon={<CartIcon h={"15px"} w={"15px"} color={iconBoxInside} />}
              />
              <ChartStatistics
                title={top4Departments[3]?.productSlug.substring(0, 4) + "..."}
                amount={top4Departments[3]?.totalSales}
                percentage={
                  (top4Departments[3]?.totalSales * 100) /
                  (top4Departments[0]?.totalSales +
                    top4Departments[1]?.totalSales +
                    top4Departments[2]?.totalSales +
                    top4Departments[3]?.totalSales)
                }
                icon={<StatsIcon h={"15px"} w={"15px"} color={iconBoxInside} />}
              />
            </SimpleGrid>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ActiveUsers;
