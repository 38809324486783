import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
// Assets
import signInImage from "assets/img/signInImage.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ErrorTextComponent from "components/ErrorTextComponent/ErrorTextComponent";
import { jwtDecode } from "jwt-decode";
import { RingLoader, ScaleLoader } from "react-spinners";
import { position } from "stylis";
import apiClient from "api/apiClient";
function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const history = useHistory();
  const [userame, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrormessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = () => {
    const obj = { email: userame, password: password, platform: "treasury" };
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API_URL}/api/Authorization/Login`,
        obj
      )
      .then((res) => {
        setIsLoading(false);
        setErrormessage(null);
        if (res.status == 200) {
          let token = res.data.userToken;
          const tokenValue = jwtDecode(res.data.userToken);
          console.log("🚀 ~ .then ~ tokenValue:", tokenValue);
          var roles =
            tokenValue[
              "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ];

          if (roles?.indexOf("Treasury") > -1) {
            console.log("Signed in user");
            localStorage.setItem("token", token);
            localStorage.setItem("user", tokenValue.user);
            apiClient;
            history.push("/treasury/dashboard");
          } else {
            setErrormessage("Account Forbidden");

            console.log("🚀 ~ .then ~ setErrormessage:", errorMessage);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("There was an error!", error);
        if (error?.response?.status === 404) {
          setErrormessage(error?.response?.data);
        }
      });
  };
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              SDA Kampala Central
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and password to sign in
            </Text>
            <Box mb={2}>
              <ErrorTextComponent
                errorMessage={errorMessage}
                visible={errorMessage}
              />
            </Box>

            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                borderRadius="15px"
                mb="24px"
                fontSize="sm"
                type="text"
                placeholder="Your email adress"
                size="lg"
                onChange={(e) => setUsername(e.target.value)}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <Input
                borderRadius="15px"
                mb="36px"
                fontSize="sm"
                type="password"
                placeholder="Your password"
                size="lg"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControl display="flex" alignItems="center">
                <Switch id="remember-login" colorScheme="teal" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  ms="1"
                  fontWeight="normal"
                >
                  Remember me
                </FormLabel>
              </FormControl>
              <Button
                fontSize="10px"
                type="submit"
                bg="teal.300"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                onClick={handleSignIn}
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                display={"flex"}
                justifyContent={"center"}
                gap={2}
              >
                SIGN IN
                <ScaleLoader
                  color={"white"}
                  loading={isLoading}
                  cssOverride={true}
                  size={60}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Forgot Password?
                <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                  Reset
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
