import { create } from "apisauce";

const apiClient = create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  // //baseURL: "https://localhost:5001",
  // //baseURL: "https://api.kampalacentraladventist.org",
  // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
});

apiClient.addRequestTransform((request) => {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
});
export default apiClient;
