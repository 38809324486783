import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Projects from "views/Dashboard/Tables/components/Projects";

function TablesTableRow(props) {
  const {
    logo,
    name,
    email,
    subdomain,
    domain,
    status,
    date,
    orderId,
    offerDetail,
    selectedRow,
    setSelectedRow,
    short,
  } = props;
  console.log("🚀 ~ TablesTableRow ~ orderId:", orderId);
  console.log("🚀 ~ TablesTableRow ~ selectedRow:", selectedRow);

  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("green.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <>
      <Tr>
        <Td minWidth={{ sm: "250px" }} pl="0px">
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            {!short && (
              <Avatar src={logo} w="50px" borderRadius="12px" me="18px" />
            )}
            <Flex direction="column">
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {name}
              </Text>
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                {email}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td minWidth={"10rem"}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="normal">
              {domain}
            </Text>
            {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {subdomain}
            </Text> */}
          </Flex>
        </Td>
        <Td>
          <Badge
            bg={status === "Online" ? "green.400" : bgStatus}
            color={status === "Online" ? "white" : colorStatus}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {status}
          </Badge>
        </Td>
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {date}
          </Text>
        </Td>
        <Td>
          <Button
            p="0px"
            bg="transparent"
            variant="no-hover"
            onClick={() => {
              if (selectedRow && selectedRow[0]?.orderId === orderId) {
                setSelectedRow(null);
              } else {
                setSelectedRow(offerDetail);
              }
            }}
          >
            {!short && (
              <Badge
                bg={
                  selectedRow && selectedRow[0]?.orderId === orderId
                    ? "green.700"
                    : bgStatus
                }
                color={status === "Online" ? "white" : colorStatus}
                fontSize="16px"
                p="10px 15px"
                borderRadius="8px"
              >
                {selectedRow && selectedRow[0]?.orderId === orderId
                  ? "Show less"
                  : "Show more"}
              </Badge>
            )}
          </Button>
        </Td>
      </Tr>
      {selectedRow && selectedRow[0]?.orderId === orderId && (
        <Tr>
          <Td colSpan={4}>
            <Projects
              title={"Offertory Detail"}
              captions={["Category", "Amount", "Comment", ""]}
              data={selectedRow}
            />
          </Td>
        </Tr>
      )}
    </>
  );
}

export default TablesTableRow;
