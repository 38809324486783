import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";

function TablesProjectRow(props) {
  const { category, amount, comment, orderDetail } = props;
  console.log("🚀 ~ DashboardTableRow ~ orderDetail:", orderDetail);
  console.log("🚀 ~ DashboardTableRow ~ props:", props);
  const textColor = useColorModeValue("gray.700", "white");
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex alignItems="center" py=".1rem" minWidth="100%" flexWrap="nowrap">
          {/* <Icon as={logo} h={"24px"} w={"24px"} me="18px" /> */}
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="regular"
            minWidth="100%"
          >
            {toTitleCase(category)}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="regular" pb=".1rem">
          {amount}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="regular" pb=".1em">
          {comment}
        </Text>
      </Td>

      <Td>
        {/* <Button p="0px" bg="transparent">
          <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
        </Button> */}
      </Td>
    </Tr>
  );
}

export default TablesProjectRow;
