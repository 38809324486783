/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import SignIn from "views/Auth/SignIn";
import AdminLayout from "layouts/Admin.js";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

ReactDOM.render(
  <HashRouter>
    <MantineProvider
      withNormalizeCSS
      withGlobalStyles
      position="top-right"
      zIndex={2077}
    >
      {" "}
      <NotificationsProvider position="top-right" zIndex={2077}>
        <Switch>
          {/* <Route path={`/auth/login-page`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          <Redirect from={`/`} to="/admin/dashboard" /> */}
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          {/* <Route path={`/rtl`} component={RTLLayout} /> */}
          <Redirect from={`/`} to="/admin/dashboard" />
        </Switch>
      </NotificationsProvider>
    </MantineProvider>
  </HashRouter>,
  document.getElementById("root")
);
