// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Authors from "./components/Authors";

import { tablesTableData, dashboardTableData } from "variables/general";
import reports from "api/reports";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";

function Tables() {
  const [offertoryDetail, setOffertoryDetail] = useState([]);
  console.log("🚀 ~ Tables ~ offertoryDetail:", offertoryDetail);
  const [offerListPage, setOfferListPage] = useState(1);
  const [offerPageSize, setPageSize] = useState(20);
  useEffect(() => {
    getOffertoryList();
  }, []);
  const getOffertoryList = async () => {
    //var userId = userProfileId ? userProfileId : userContext.user.UserId;
    // console.log("userId and user context", userContext, userId);

    var result = await reports.tryGetAllOffertory(offerListPage, offerPageSize);
    if (!result.ok) {
      showNotification({
        id: "save-data",
        icon: <IconX size={16} />,
        title: "Error",
        message: `${result.status ? result.status : ""} ${result.problem}`,
        autoClose: true,
        disallowClose: false,
        style: { zIndex: "999999" },
      });
      return;
    }

    setOffertoryDetail(result.data);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Authors
        title={"Offertory Summary"}
        captions={["Name", "Date paid", "Amount", "Payment mode", ""]}
        data={offertoryDetail}
      />
    </Flex>
  );
}

export default Tables;
