import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const lineChartData = [
  {
    name: "Mobile apps",
    data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
  },
  {
    name: "Websites",
    data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
  },
];

const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: true,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#4FD1C5", "#2D3748", "blue", "red"],
  },
  colors: ["#4FD1C5", "#2D3748", "blue", "red"],
};

const LineChart = (props) => {
  const [chartData, setChartData] = useState();
  // console.log("🚀 ~ LineChart ~ chartData:", chartData);
  const [chartOptions, setChartOptions] = useState();
  // console.log("🚀 ~ LineChart ~ chartOptions:", chartOptions);

  useEffect(() => {
    setChartData(props.lineChartData);
    setChartOptions(props.lineChartOptions);
  }, [props.lineChartData, props.lineChartOptions]);

  return (
    <>
      {chartData && chartOptions ? (
        <ReactApexChart
          options={chartOptions}
          series={chartData}
          type="area"
          width="100%"
          height="100%"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default LineChart;
