// Chakra imports
import { Box, Flex, Grid, Icon } from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import React, { useEffect, useState } from "react";
import { FaPaypal, FaWallet } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";
import BillingInformation from "./components/BillingInformation";
import CreditCard from "./components/CreditCard";
import Invoices from "./components/Invoices";
import PaymentMethod from "./components/PaymentMethod";
import PaymentStatistics from "./components/PaymentStatistics";
import Transactions from "./components/Transactions";
import reports from "api/reports";
import { IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

function Billing() {
  const [accountBalances, setAccountBalances] = useState(null);
  console.log("🚀 ~ Billing ~ accountBalances:", accountBalances);
  useEffect(() => {
    getOffertoryTotals();
  }, []);

  const getOffertoryTotals = async () => {
    var result = await reports.tryGetOffertoryBalances();
    if (!result.ok) {
      showNotification({
        id: "save-data",
        icon: <IconX size={16} />,
        title: "Error",
        message: `${result.status ? result.status : ""} ${result.problem}`,
        autoClose: true,
        disallowClose: false,
        style: { zIndex: "999999" },
      });
      return;
    }

    setAccountBalances(result.data);
  };
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "2fr 1.2fr" }} templateRows="1fr">
        <Box>
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            }}
            templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
            gap="26px"
          >
            <CreditCard
              backgroundImage={BackgroundCard1}
              title={"Combined Acc Balance".toUpperCase()}
              number={accountBalances?.summaryResult?.totalCollections ?? 0}
              validity={{
                name: "",
                data: "05/24",
              }}
              cvv={{
                name: "",
                code: "",
              }}
              icon={
                ""
                // <Icon
                //   as={RiMastercardFill}
                //   w='48px'
                //   h='auto'
                //   color='gray.400'
                // />
              }
            />
            <PaymentStatistics
              icon={<Icon h={"24px"} w={"24px"} color="white" as={FaWallet} />}
              title={"Momo balance"}
              description={"via Beyonic"}
              amount={accountBalances?.summaryBalances?.balanceMobileMoney ?? 0}
            />
            <PaymentStatistics
              icon={<Icon h={"24px"} w={"24px"} color="white" as={FaPaypal} />}
              title={"Card Balance"}
              description={"via One Global"}
              amount={accountBalances?.summaryBalances?.balanceCard ?? 0}
            />
          </Grid>
          <PaymentMethod
            title={"Payment Method"}
            mastercard={{
              icon: <MastercardIcon w="100%" h="100%" />,
              number: "7812 2139 0823 XXXX",
            }}
            visa={{
              icon: <VisaIcon w="100%" h="100%" />,
              number: "7812 2139 0823 XXXX",
            }}
          />
        </Box>
        <Invoices title={"Recent Transfers"} data={invoicesData} />
      </Grid>
      {/* <Grid templateColumns={{ sm: "1fr", lg: "1.6fr 1.2fr" }}>
        <BillingInformation title={"Billing Information"} data={billingData} />
        <Transactions
          title={"Your Transactions"}
          date={"23 - 30 March"}
          newestTransactions={newestTransactions}
          olderTransactions={olderTransactions}
        />
      </Grid> */}
    </Flex>
  );
}

export default Billing;
