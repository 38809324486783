import apiClient from "./apiClient";

const endpointOffertoryDetail = "api/Treasury/ListOffertory";
const endpOffertoryTotals = "api/Treasury/GetDashBoardTotals";
const endpOffertoryGraphs = "api/Treasury/GetDataForGraphs";
const endpOffertoryBalances = "api/Treasury/GetAccountBalnacePerMethod";

const tryGetAllOffertory = (pageNumber, pageSize) =>
  apiClient.get(
    endpointOffertoryDetail + `?page=${pageNumber}&pageSize=${pageSize}`
  );
const tryGetOffertoryTotals = () => apiClient.get(endpOffertoryTotals);
const tryGetOffertoryGraphs = () => apiClient.get(endpOffertoryGraphs);
const tryGetOffertoryBalances = () => apiClient.get(endpOffertoryBalances);
export default {
  tryGetAllOffertory,
  tryGetOffertoryTotals,
  tryGetOffertoryGraphs,
  tryGetOffertoryBalances,
};
