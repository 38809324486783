// Chakra imports
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import { WalletIcon } from "components/Icons/Icons.js";
import React, { useEffect, useState } from "react";
import { dashboardTableData, timelineData } from "variables/general";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";
import { lineChartOptions, barChartOptions } from "variables/charts";
import dateFormat from "dateformat";
import {
  IconPremiumRights,
  IconCalendarTime,
  IconCreditCard,
  IconX,
} from "@tabler/icons";
import reports from "api/reports";
import { showNotification } from "@mantine/notifications";
import {
  FaArrowDown,
  FaArrowUp,
  FaBell,
  FaCreditCard,
  FaFilePdf,
  FaHtml5,
  FaShoppingCart,
} from "react-icons/fa";
import Authors from "../Tables/components/Authors";

export default function Dashboard() {
  const iconBoxInside = useColorModeValue("white", "white");
  const [offertorySums, setOffertorySums] = useState(null);
  const [offertoryGraphs, setOffertoryGraphs] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [listOffertory, setListOffertory] = useState([]);
  const [timelineDataState, setTimeLineDataState] = useState([]);
  const [lineChartOptionsState, setLineChartOptionsState] = useState(
    lineChartOptions
  );
  const [barChartData, setBarChartData] = useState(null);
  const [barChartOptionsState, setBarChartOptionsState] = useState(
    barChartOptions
  );

  useEffect(() => {
    getOffertoryTotals();
    getOffertoryGraphs();
    getOffertoryList();
  }, []);
  useEffect(() => {
    updateLineGraphs();
    updateBarGraphs();
  }, [offertoryGraphs]);
  useEffect(() => {
    updateMostRecent();
  }, [listOffertory]);
  const getOffertoryTotals = async () => {
    var result = await reports.tryGetOffertoryTotals();
    if (!result.ok) {
      showNotification({
        id: "save-data",
        icon: <IconX size={16} />,
        title: "Error",
        message: `${result.status ? result.status : ""} ${result.problem}`,
        autoClose: true,
        disallowClose: false,
        style: { zIndex: "999999" },
      });
      return;
    }

    setOffertorySums(result.data);
  };
  const getOffertoryList = async () => {
    var result = await reports.tryGetAllOffertory(1, 10);
    if (!result.ok) {
      showNotification({
        id: "save-data",
        icon: <IconX size={16} />,
        title: "Error",
        message: `${result.status ? result.status : ""} ${result.problem}`,
        autoClose: true,
        disallowClose: false,
        style: { zIndex: "999999" },
      });
      return;
    }

    console.log("🚀 ~ getOffertoryList ~ result.data:", result.data);
    setListOffertory(result.data);
  };
  const updateLineGraphs = () => {
    function getLast12MonthsSeries(data) {
      const currentDate = new Date();
      const seriesData = [];

      // Generate the last 12 months in the format { year: YYYY, month: M }
      const months = [];
      for (let i = 0; i < 12; i++) {
        const month = currentDate.getMonth(); // getMonth() is zero-based
        const year = currentDate.getFullYear();
        const monthName = currentDate.toLocaleString("default", {
          month: "short",
        }); // Get 3-letter month name
        months.unshift({ year, month, monthName }); // Insert at the beginning

        currentDate.setMonth(currentDate.getMonth() - 1);
      }

      // Process each product
      data.forEach((product) => {
        const productSeries = Array(12).fill(0); // Initialize with zero values

        product.monthlySales.forEach((sale) => {
          // Find the index in the months array
          const index = months.findIndex(
            (m) => m.year === sale.year && m.month === sale.month - 1
          );
          if (index !== -1) {
            productSeries[index] = sale.totalSales;
          }
        });

        seriesData.push({
          name: product.productSlug,
          data: productSeries,
        });
      });

      return {
        months: months.map((m) => m.monthName), // Convert to 3-letter month format
        seriesData,
      };
    }

    if (offertoryGraphs) {
      const result = getLast12MonthsSeries(offertoryGraphs.topProductSlugs);

      setLineChartData(result.seriesData);

      const copyOptions = lineChartOptionsState;
      copyOptions.xaxis.categories = result.months;
      setLineChartOptionsState(copyOptions);
    }
  };
  const updateBarGraphs = () => {
    function getLast12MonthsTotals(monthlyTotals) {
      if (!monthlyTotals) return;
      const currentDate = new Date();
      const months = [];
      const totals = Array(12).fill(0); // Initialize with zeros

      // Generate the last 12 months in the format { year: YYYY, month: M }
      for (let i = 0; i < 12; i++) {
        const month = currentDate.getMonth(); // getMonth() is zero-based
        const year = currentDate.getFullYear();
        const monthName = currentDate.toLocaleString("default", {
          month: "short",
        }); // Get 3-letter month name
        months.unshift({ year, month, monthName }); // Insert at the beginning

        currentDate.setMonth(currentDate.getMonth() - 1);
      }

      // Fill in the totals based on the monthlyTotals data
      monthlyTotals.forEach((mt) => {
        const index = months.findIndex(
          (m) => m.year === mt.year && m.month === mt.month - 1
        );
        if (index !== -1) {
          totals[index] = mt.total;
        }
      });

      // Combine month names with totals
      const result = months.map((m, index) => ({
        name: "Monthly Totals",
        label: m.monthName,
        data: totals[index],
      }));

      return result;
    }

    if (offertoryGraphs) {
      const result = getLast12MonthsTotals(offertoryGraphs.monthlyTotals);
      // // console.log("🚀 ~ updateBarGraphs ~ result:", result);
      const resultOut = {
        name: result[0]?.name,
        data: result.map((d) => d.data),
      };
      setBarChartData([resultOut]);

      const copyOptions = barChartOptionsState;
      copyOptions.xaxis.categories = result.map((m) => m.label);
      // // console.log(
      // // "🚀 ~ updateBarGraphs ~ copyOptions.xaxis.categories:",
      // // copyOptions.xaxis.categories
      // // );

      setBarChartOptionsState(copyOptions);
    }
  };
  const getOffertoryGraphs = async () => {
    var result = await reports.tryGetOffertoryGraphs();
    if (!result.ok) {
      showNotification({
        id: "save-data",
        icon: <IconX size={16} />,
        title: "Error",
        message: `${result.status ? result.status : ""} ${result.problem}`,
        autoClose: true,
        disallowClose: false,
        style: { zIndex: "999999" },
      });
      return;
    }

    // // console.log("🚀 ~ getOffertoryGraphs ~ result.data:", result.data);
    setOffertoryGraphs(result.data);
  };

  const updateMostRecent = () => {
    if (listOffertory) {
      const colors = ["purple", "blue.400", "orange", "teal.300", "orange.300"];

      const timelineDataOut = listOffertory?.map((order) => {
        const randomIndex = Math.floor(Math.random() * colors.length);
        const orderOut = {
          logo: FaCreditCard,
          title: `UGX${order.paymentInfo.paymentAmount.toLocaleString(
            "en-US"
          )}, #${order.orderId} - ${order.customerName}`,
          date: `${dateFormat(order.orderDate)}`,
          color: `${colors[randomIndex]}`,
        };
        return orderOut;
      });
      console.log("🚀 ~ updateMostRecent ~ timelineData:", timelineDataOut);
      setTimeLineDataState(timelineDataOut);
    }
  };
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <MiniStatistics
          title={"Today's Offertory"}
          amount={offertorySums?.todaysOffer}
          percentage={offertorySums?.percentageToday}
          icon={<IconPremiumRights size={30} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Last 7 days"}
          amount={offertorySums?.lastSevenDays}
          percentage={offertorySums?.percentageToday}
          icon={<IconCalendarTime size={30} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Total Collections"}
          amount={offertorySums?.totalCollections}
          percentage={""}
          icon={<IconCreditCard size={30} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Current Balance"}
          amount={offertorySums?.balance}
          percentage={""}
          icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>

      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap="24px"
        // mb={{ lg: "26px" }}
        my="26px"
      >
        <ActiveUsers
          title={"Total collections monthly"}
          title2={"Top 4 totals"}
          percentage={""}
          top4Departments={offertoryGraphs?.topProductSlugs}
          chart={
            <BarChart
              barChartOptions={barChartOptionsState}
              barChartData={barChartData}
            />
          }
        />
        <SalesOverview
          title={"Top Departments"}
          percentage={5}
          chart={
            <LineChart
              lineChartData={lineChartData}
              lineChartOptions={lineChartOptionsState}
            />
          }
        />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        gap="24px"
      >
        <Authors
          title={"Offertory Summary"}
          captions={["Name", "Date paid", "Amount", "Payment mode"]}
          data={listOffertory}
          short={true}
        />

        <OrdersOverview
          title={"Most recent payments"}
          amount={30}
          data={timelineDataState}
        />
      </Grid>
    </Flex>
  );
}
