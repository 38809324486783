// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "components/Tables/TablesTableRow";
import React, { useState } from "react";
import dateFormat, { masks } from "dateformat";

const Authors = ({ title, captions, data, short }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {captions.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <TablesTableRow
                  key={`${row.customerName}-${row.customerPhone}`}
                  name={row.customerName}
                  logo={row.logo}
                  email={row.customerPhone}
                  orderId={row.orderId}
                  subdomain={""}
                  domain={dateFormat(row.orderDate)}
                  status={row.paymentInfo.paymentAmount?.toLocaleString(
                    "en-US",
                    { style: "currency", currency: "UGX" }
                  )}
                  date={row.paymentInfo.paymentMode}
                  offerDetail={row.orderDetails}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                  short={short}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Authors;
