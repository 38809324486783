import React, { useState, useEffect } from "react";
import Card from "components/Card/Card";
import Chart from "react-apexcharts";
// import { barChartData, barChartOptions } from "variables/charts";

const BarChart = (props) => {
  const [chartData, setChartData] = useState();
  // console.log("🚀 ~ BarChart ~ chartData:", chartData);
  const [chartOptions, setChartOptions] = useState();
  // console.log("🚀 ~ BarChart ~ chartOptions:", chartOptions);

  useEffect(() => {
    setChartData(props.barChartData);
    setChartOptions(props.barChartOptions);
  }, [props.barChartData, props.barChartOptions]);

  return (
    <Card
      py="1rem"
      height={{ sm: "200px" }}
      width="100%"
      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
      position="relative"
    >
      {chartData && chartOptions ? (
        <Chart
          options={chartOptions}
          series={chartData}
          type="bar"
          width="100%"
          height="100%"
        />
      ) : (
        ""
      )}
    </Card>
  );
};

export default BarChart;
